

















import { Vue, Component, Prop } from 'vue-property-decorator'
import { IEnumData } from '@/constant/models'

@Component({
  name: 'SelectableSpan'
})
export default class extends Vue {
  @Prop({ default: '' }) private label!: string
  @Prop({
    default: function(): IEnumData[] {
      return []
    }
  }) private list!: IEnumData[]

  private onCommand(commend: IEnumData) {
    this.$emit('update', commend)
  }
}
