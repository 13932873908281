










































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ICustomerData, ISyncIdentityData } from '@/api/types'
import ArrayUtil from '@/utils/array'

@Component({
  name: 'CustomerDetailIdentity'
})
export default class extends Vue {
  @Prop({
    default: function(): ICustomerData {
      return {
        contactWayDictIds: [],
        contactWayTitles: [],
        contactWays: [],
        identity: {
          list: []
        },
        tags: []
      }
    }
  }) private customer!: ICustomerData

  private get identityList() {
    return ArrayUtil.filter<ISyncIdentityData>(this.customer?.identity?.list)
  }

  private get currentName() {
    return this.customer?.identity?.currentIdentity?.customerIdentityName ?? '无身份'
  }

  private get untilTime() {
    return this.customer?.identity?.currentIdentity?.end ?? '无'
  }
}
