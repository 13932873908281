


























import { Vue, Component, Prop } from 'vue-property-decorator'
import { IKeyRoleData } from '@/api/types'

@Component({
  name: 'CustomerDetailKeyRoles'
})
export default class extends Vue {
  @Prop({
    default: function() {
      return []
    },
    required: true
  }) private keyRoles!: IKeyRoleData[]
}
