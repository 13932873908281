









































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import MarketingApi from '@/api/marketing'
import { IOrderData } from '@/api/types'
import OrderExpandProductHeader from '@/components/OrderExpandProductHeader/index.vue'
import OrderExpandProductItem from '@/components/OrderExpandProductItem/index.vue'

@Component({
  name: 'CustomerDetailOrder',
  components: { OrderExpandProductItem, OrderExpandProductHeader }
})
export default class extends Vue {
  @Prop({ default: '' }) private customerId!: string

  @Watch('customerId', { immediate: true })
  private onCustomerIdChange(id: string) {
    if (id) {
      this.getOrderList()
    }
  }

  private totalAmount = 0

  private list: IOrderData[] = []

  private async getOrderList() {
    if (!this.customerId) {
      return
    }
    const { data } = await MarketingApi.customerOrders({
      CustomerId: this.customerId
    })
    if (data) {
      this.list = data.orders
      this.totalAmount = data.totalAmount
    }
  }
}
