





























import { Vue, Component, Prop } from 'vue-property-decorator'
import ListTile from '@/components/ListTile/index.vue'
import { IWecomCustomerData } from '@/api/types'
import Empty from '@/components/Empty/index.vue'

@Component({
  name: 'CustomerDetailWecom',
  components: {
    Empty,
    ListTile
  }
})
export default class extends Vue {
  @Prop({ default: false }) private hasWecomData!: boolean
  @Prop({
    default: function(): IWecomCustomerData {
      return {
        followUsers: [],
        id: '',
        mobiles: [],
        tags: []
      }
    }
  }) private wecomData!: IWecomCustomerData
}
