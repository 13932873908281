

















import { Vue, Component, Prop } from 'vue-property-decorator'
import Empty from '@/components/Empty/index.vue'
import { IJourneyData } from '@/api/types'

@Component({
  name: 'CustomerDetailJourney',
  components: { Empty }
})
export default class extends Vue {
  @Prop({
    default: function() {
      return []
    },
    required: true
  }) private journey!: IJourneyData[]
}
