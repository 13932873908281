
































import { Vue, Component, Prop } from 'vue-property-decorator'
import { IClueData } from '@/api/types'

@Component({
  name: 'CustomerDetailClue'
})
export default class extends Vue {
  @Prop({
    default: function() {
      return []
    },
    required: true
  }) private clues!: IClueData[]
}
