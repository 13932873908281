





























































import { Vue, Component, Prop } from 'vue-property-decorator'
import ListTile from '@/components/ListTile/index.vue'
import LensSpan from '@/components/LensSpan/index.vue'
import { DpBackendCustomersSearchOneCustomerDto } from '@/proxy/models'
@Component({
  name: 'MergeCustomerDialog',
  components: {
    ListTile,
    LensSpan
  }
})
export default class extends Vue {
    @Prop({ default: '' }) private visible!: boolean
    @Prop({ default: '' }) private showMergePerson!: boolean
    @Prop({ default: '' }) private mergePhone!: string
    @Prop({ default: '' }) private mergeData!: DpBackendCustomersSearchOneCustomerDto
    @Prop({ default: '' }) private customerId!: string

    private showMergeDialog = false

    private hideMergeDialog() {
      this.onDialogClose()
    }

    private saveMerge() {
      this.$emit('onSaveMerge')
    }

    private onDialogClose() {
      this.$emit('onMergeDialogHide', this.showMergeDialog)
    }
}
