






















































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ListTile from '@/components/ListTile/index.vue'
import { AppModule } from '@/store/modules/app'
import { IClueData, ICustomerData, ITagData, IWecomCustomerData } from '@/api/types'
import TextUtil from '@/utils/text'
import CustomerApi from '@/api/customer'
import CustomerDetailOrder from '@/views/customer/customer-detail/components/order/index.vue'
import EditableSpan from '@/components/EditableSpan/index.vue'
import SelectableSpan from '@/components/SelectableSpan/index.vue'
import Constant from '@/constant/constant'
import { IContactData, IEnumData } from '@/constant/models'
import EditableRemark from '@/components/EditableRemark/index.vue'
import Empty from '@/components/Empty/index.vue'
import AddContactDialog from '@/dialogs/add-contact/index.vue'
import CustomerDetailWecom from '@/views/customer/customer-detail/components/wecom/index.vue'
import MergeCustomerDialog from '@/views/customer/customer-detail/components/merge/index.vue'
import CustomerDetailJourney from '@/views/customer/customer-detail/components/journey/index.vue'
import CustomerDetailKeyRoles from '@/views/customer/customer-detail/components/key-roles/index.vue'
import CustomerDetailClue from '@/views/customer/customer-detail/components/clue/index.vue'
import WecomApi from '@/api/wecom'
import CustomerDetailIdentity from '@/views/customer/customer-detail/components/identity/index.vue'
import Events from '@/constant/events'
import LensSpan from '@/components/LensSpan/index.vue'
import PermissionConst from '@/constant/permissions'
import { UserModule } from '@/store/modules/user'
import DateUtil from '@/utils/date'
import { CustomerApi as ProxyCustomerApi, WebCallApi } from '@/proxy'
import { DpBackendCustomersSearchOneCustomerDto } from '@/proxy/models'

@Component({
  name: 'CustomerDetail',
  components: {
    LensSpan,
    CustomerDetailIdentity,
    CustomerDetailClue,
    CustomerDetailKeyRoles,
    CustomerDetailJourney,
    CustomerDetailWecom,
    MergeCustomerDialog,
    AddContactDialog,
    Empty,
    EditableRemark,
    SelectableSpan,
    EditableSpan,
    CustomerDetailOrder,
    ListTile
  }
})
export default class extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: '' }) private customerId!: string
  @Prop({
    default: '',
    required: true
  }) private source!: string

  private genderTypes = Constant.GenderType
  private isAddContactShow = false

  private showDateDialog = false
  private showMergeDialog = false
  private showMerge = false
  private birthdayDate: Date = new Date()
  private _customerId = ''

  private customer: ICustomerData = {
    contactWayDictIds: [],
    contactWayTitles: [],
    contactWays: [],
    tags: [],
    identity: {
      list: []
    },
    id: ''
  }

  private wecomData: IWecomCustomerData = {
    followUsers: [],
    mobiles: [],
    tags: [],
    id: ''
  }

  private mergeData: DpBackendCustomersSearchOneCustomerDto = {}
  private mergePhoneData = ''

  private activeName = 'first'
  private clueList: IClueData[] = []
  private studyTimelines = [{
    content: '《了不起的孩子》',
    timestamp: '2022-03-04 14:23:13 开始学习，用时 14:32',
    lessons: []
  }, {
    content: '《大脑的一天》 ',
    timestamp: '2018-04-13 14:23:13 开始学习，用时 14:32',
    lessons: [
      '02.保险产品+生态链 | 王润东、管清友',
      '02.保险产品+生态链 | 王润东、管清友'
    ]
  }, {
    content: '杨澜专访《保险灼见》',
    timestamp: '2018-04-11 14:23:13 开始学习，用时 14:32',
    lessons: []
  }]

  @Watch('customerId', { immediate: true })
  private async onCustomerIdChange(customerId: string) {
    this._customerId = customerId
    if (TextUtil.isNotEmpty(this._customerId)) {
      await this.refreshCustomerDetail()
      await this.refreshWecomCustomerDetail()
      await this.refreshClues()
    }
  }

  created() {
    this._customerId = this.customerId
    this.$EventBus.$on(Events.TAG_SET, (data: Map<string, ITagData[]>) => {
      if (this.customer.id && data.has(this.customer.id)) {
        this.customer.tags = data.get(this.customer.id) ?? []
      }
    })
    this.$EventBus.$on(Events.ADD_CONTACT, (customerId: string, contactList: IContactData[]) => {
      if (this._customerId === customerId && contactList && contactList.length > 0) {
        this.customer.contactWayTitles = []
        this.customer.contactWays = []
        this.customer.contactWayDictIds = []
        contactList.forEach(contact => {
          this.customer.contactWayTitles?.push(contact.methodName)
          this.customer.contactWays?.push(contact.value)
          this.customer.contactWayDictIds?.push(contact.dictId)
        })
      }
    })
  }

  destroyed() {
    this.$EventBus.$off(Events.TAG_SET)
    this.$EventBus.$off(Events.ADD_CONTACT)
  }

  private get hasMergePermission() {
    return PermissionConst.hasPermission(UserModule.permissions, PermissionConst.Group.DpBackend.Customers.Merge)
  }

  private get showAddContact() {
    return PermissionConst.hasPermission(UserModule.permissions, PermissionConst.Group.DpBackend.Customers.AddContactWay)
  }

  private onTagSettingClick() {
    this.$emit('tagSettingClick')
  }

  private async onEditRemarkConfirm(editRemark: string) {
    if (!editRemark) {
      return
    }
    const { status } = await CustomerApi.editCustomerRemark(this._customerId, editRemark)
    if (status === 204) {
      this.customer.remark = editRemark
    }
  }

  private createClue() {
    AppModule.OpenCreateClueDialog({
      contactWayDictIds: this.$filterArray(this.customer.contactWayDictIds),
      contactWayTitles: this.$filterArray(this.customer.contactWayTitles),
      contactWays: this.$filterArray(this.customer.contactWays),
      customerId: this.$filterString(this.customer.id),
      source: this.source,
      customerName: this.customer.name
    })
  }

  private showBirthdayDialog() {
    this.showDateDialog = true
  }

  private async showMergePhoneDialog(index: number) {
    const results = await new ProxyCustomerApi().apiAppCustomerSearchOneGet(this.customer.contactWays[index])
    this.showMergeDialog = true
    if (results.data) {
      this.showMerge = false
      this.mergeData = results.data
    } else {
      this.mergePhoneData = this.customer.contactWays[index]
      this.showMerge = true
    }
  }

  private onMergeDialogHide() {
    this.showMergeDialog = false
  }

  private async onSaveMerge() {
    const results = await new ProxyCustomerApi().apiAppCustomerIdMergePost(this._customerId, { id: this.mergeData.id })
    if (results.data) {
      this.$message.success('合并客户成功')
      this._customerId = results.data
      await this.refreshCustomerDetail()
      await this.refreshWecomCustomerDetail()
      await this.refreshClues()
      this.onMergeDialogHide()
    }
  }

  private async saveBirthday() {
    const { status } = await CustomerApi.editCustomerBirthday(this._customerId, this.birthdayDate)
    if (status === 200) {
      this.showDateDialog = false
      this.customer.birthday = DateUtil.dateFormat(this.birthdayDate, 'yyyy-MM-dd')
    }
  }

  private async refreshClues() {
    if (!this.customer.id) {
      return
    }
    const { data } = await CustomerApi.getCustomerClues({
      customerId: this.customer.id
    })
    if (data) {
      this.clueList = data.items
    }
  }

  private async refreshWecomCustomerDetail() {
    if (!this.customer.weComUserId) {
      return
    }
    const { data } = await WecomApi.wecomCustomerInfo(this.customer.weComUserId)
    if (data) {
      this.wecomData = data
    }
  }

  private async refreshCustomerDetail() {
    if (!this._customerId) {
      return
    }
    const { data } = await CustomerApi.customerDetail(this._customerId)
    if (data) {
      this.customer = data
      if (this.customer.birthday) {
        this.birthdayDate = DateUtil.toDate(this.customer.birthday)
      }
    }
  }

  private async onCustomerNameUpdate(name: string) {
    const { status } = await CustomerApi.editCustomerName(this._customerId, name)
    if (status === 204) {
      this.customer.name = name
    }
  }

  private async onCustomerNicknameUpdate(nick: string) {
    const { status } = await CustomerApi.editCustomerNickname(this._customerId, nick)
    if (status === 200) {
      this.customer.nick = nick
    }
  }

  private async onCustomerGenderUpdate(gender: IEnumData) {
    const { status } = await CustomerApi.editCustomerGender(this._customerId, gender.value)
    if (status === 204) {
      this.customer.gender = gender.value
    }
  }

  private async onCustomerContactUpdate(index: number, contact: string) {
    const tempArr = this.customer.contactWays.concat()
    tempArr[index] = contact
    const contactList: IContactData[] = []
    tempArr.forEach((way, i) => {
      contactList.push({
        value: way,
        dictId: this.customer.contactWayDictIds[i],
        methodName: this.customer.contactWayTitles[i]
      })
    })
    const { status } = await CustomerApi.editCustomerContact(this._customerId, contactList)
    if (status === 204) {
      this.customer.contactWays = tempArr
    }
  }

  private async callPhone(number: string) {
    const { data } = await new WebCallApi().apiAppWebCallCallPost({
      customerId: this.customerId,
      customerMobile: number
    })
    if (!data?.isOk) {
      this.$message.error(data.message)
    }
  }
}
